import Link from 'next/link';
import PropTypes from 'prop-types';
import MicroModal from 'micromodal';
import dynamic from 'next/dynamic';

// Utils
import { cn } from 'utils';
import checkLexicalJSONValidity from '../../../src/utils/checkLexicalJSONValidity';

// Styles
import styles from './TextBlock.module.css';

// Components
import Button from '../../atoms/Button/Button';
import Title from '../../atoms/Title/Title';
import RichTextContent from '../../atoms/RichTextContent/RichTextContent';

const Modal = dynamic(() => import('../../organisms/Modal/Modal'), { ssr: false });

function TextBlock({
  title,
  subtitle,
  content,
  label,
  link,
  theme,
  additionalButton,
  titleTag,
  contentFontSize,
  isIframe,
  ctaType,
  className,
}) {
  const handleLinkInIframe = (id) => {
    MicroModal.show(id);
  };

  const getButtonElement = () => {
    let contentChildren = (
      <>
        {label ? (
          <Link href={link} name={label}>
            <Button
              icon="arrowOutward"
              iconPosition="right"
              CTAType={ctaType}
              usageContext={theme}
            >
              {label}
            </Button>
          </Link>
        ) : null}
        {additionalButton}
      </>
    );

    if (isIframe) {
      contentChildren = (
        <Button
          icon="arrowOutward"
          iconPosition="right"
          CTAType={ctaType}
          usageContext={theme}
          onClick={() => handleLinkInIframe(`textiframe-${label}-${link}`)}
        >
          {label}
        </Button>
      );
    }

    return (
      <div className={styles.buttons}>
        {contentChildren}
      </div>
    );
  };

  return (
    <div id={subtitle} className={cn([styles.wrapper, className, styles[theme]])}>
      <div className={styles.container}>
        {title ? (
          <Title
            title={title}
            subtitle={subtitle}
            theme={theme}
            className={styles.title}
            titleTag={titleTag}
          />
        ) : null}
        {checkLexicalJSONValidity(content) ? (
          <div className={styles.contentContainer}>
            <RichTextContent content={content} className={contentFontSize ? styles[contentFontSize] : ''} />
          </div>
        ) : null}
        {label || additionalButton ? getButtonElement() : null}
      </div>
      {isIframe ? (
        <Modal
          id={`textiframe-${label}-${link}`}
          theme={link ? 'virtualVisit' : 'dark'}
          fullScreen
          hideCancelButton
        >
          {link ? (
            <iframe
              title={`${label}-${link}`}
              src={link}
              width="100%"
              height="100%"
            />
          ) : null}
        </Modal>
      ) : null}
    </div>
  );
}

TextBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  additionalButton: PropTypes.node,
  titleTag: PropTypes.string,
  contentFontSize: PropTypes.oneOf(['p1', 'p2', 'p3', '']),
  className: PropTypes.string,
  isIframe: PropTypes.bool,
  ctaType: PropTypes.oneOf(['primary', 'secondary']),
};

TextBlock.defaultProps = {
  title: '',
  subtitle: '',
  content: '',
  label: '',
  link: '',
  theme: 'light',
  additionalButton: null,
  titleTag: 'h2',
  contentFontSize: '',
  className: '',
  isIframe: false,
  ctaType: 'primary',
};

export default TextBlock;
